// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These
// styles are for the entire app and not just one component. Additionally, this
// file can hold Sass mixins, functions, and placeholder classes to be imported

// Default UI Styling
* {
  outline: none !important;
}

// - Controls
.alert-title.sc-ion-alert-md,
.alert-message {
  text-align: center;
}

.alert-title.sc-ion-alert-md,
.alert-title.sc-ion-alert-ios {
  font-weight: 300;
  --ion-text-color: #ff941a;
  font-size: 2rem;
}

.alert-title.sc-ion-alert-ios {
  margin: 1px;
}

.alert-head.sc-ion-alert-md,
.alert-head.sc-ion-alert-ios {
  border-bottom: 0.55px solid rgba(0, 0, 0, 0.2);
}

ion-alert:not(.select-alert) {

  .alert-message.sc-ion-alert-md,
  .alert-message.sc-ion-alert-ios {
    padding: 20px !important;

    p {
      margin: 0;
    }
  }
}

.alert-message.sc-ion-alert-ios {
  &:empty {
    padding: 0 !important;
  }

  p {
    font-size: 2rem;
  }
}

.alert-button-group.sc-ion-alert-md {
  border-top: 0.55px solid rgba(0, 0, 0, 0.2);
}

.alert-radio-group {
  border-top: none !important;
  border-bottom: none !important;
}


.alert-button.sc-ion-alert-md,
.alert-button.sc-ion-alert-ios {
  color: #282c37;
}

.alert-button-role-cancel.sc-ion-alert-md,
.alert-button-role-cancel.sc-ion-alert-ios {
  font-weight: 300 !important;
}

.alert-button.sc-ion-alert-md,
.alert-button.sc-ion-alert-ios {
  font-weight: 600;
}

.slip-sort-modal .alert-wrapper {
  .alert-radio-label {
    // Can't set width of box so do this to show the '(Default)'
    text-overflow: unset;
  }

  .alert-message {
    display: none;
  }
}

.split-pane-visible,
.menu-pane-visible {
  ion-menu {
    border-right: 1px solid rgba(0, 0, 0, 0.13);
  }
}

.infinite-loading {
  margin-bottom: 0 !important;
}

// datepicker
.picker-toolbar-button .picker-button,
.picker-opt-selected {
  color: #282c37 !important;
}

.searchbar-has-value {
  .searchbar-clear-button {
    display: block;
  }
}

.searchbar-clear-icon {
  width: 18px !important;
}

.searchbar-input {
  font-size: 2rem !important;
  border-bottom: 2px solid #eff0f2 !important;
  border-radius: 0 !important;
  padding-inline-start: 10px !important;
}

.searchbar-search-icon {
  display: none;
}

ion-title {
  font-weight: normal;
}

ion-header:after {
  background-image: none !important;
}

ion-toolbar:not([transparent='true']):not([noUnderline]) {
  --border-width: 0 0 0.55px;
  --border-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.2);
}

ion-footer[topBorder] {
  --border-width: 0.55px 0 0;
  --border-color: rgba(0, 0, 0, 0.2);
  border-top-color: rgba(0, 0, 0, 0.2);
  border-top: 0.55px solid #0003;
}

ion-footer[topBorder]:before {
  height: 0;
}

ion-toolbar[transparent='true'] {
  --background: transparent;
  --ion-color-base: transparent !important;

  ion-buttons {

    ion-button,
    ion-button[ionBackButton],
    ion-back-button {
      background: #fff;
      border-radius: 10%;
    }
  }
}

ion-list-header {
  margin-bottom: 0;
  border-top: none;
  border-bottom: 1px solid #f1f1f1;
  font-size: 1.8rem;
  text-transform: uppercase;
  color: #999;
}

.scrollable {
  overflow: scroll;
}

ion-checkbox {
  --border-radius: 0;
}

// - Inputs
ion-input[disabled] {
  --color: #cecece;

}

// override some unwanted ionic 7 behaviour
ion-input {
  &.ios {
    --highlight-color: #326fe0 !important;
  }

  &.md {
    --highlight-color: #000 !important;
  }

  .input-highlight {
    display: none !important;
  }
}

.native-input[disabled] {
  opacity: 1 !important;
}

.item-input.item-has-value.item-interactive-disabled {
  --border-color: #c9c9c9 !important;

  ion-label {
    --color: #c9c9c9 !important;
  }
}

.item-input.item-label-floating,
.item-input.item-label-stacked {
  --border-color: #d1d1d1;
}

.item-input.item-label-floating {
  --border-width: 0 0 1px 0;
}

.item-input.item-label-floating,
.item-input.item-label-stacked {
  ion-label {
    --color: #cecece !important;
    font-weight: 300 !important;
  }

  .input-clear-icon {
    visibility: hidden !important;
  }
}

.item-input.ion-invalid {
  --highlight-background: #d1d1d1;
}

.item-input.item-has-focus {
  --border-color: #ff941a;
  --highlight-color-valid: #ff941a;
  --highlight-background: #ff941a;

  .has-value .input-clear-icon {
    visibility: visible !important;
  }
}

.item-input.item-has-focus ion-label {
  --color: #ff941a !important;
  font-weight: 300 !important;
  font-size: 2rem;
}

// - Buttons
ion-back-button,
ion-button[ionBackButton] {
  height: 32px;
}

ion-button {
  // --background-focused: transparent !important;
  // --background-hover: transparent !important;
  // transition: opacity 0.3s;
  text-transform: none;

  &.ios {
    min-height: auto;
  }
}

// ion-button:hover {
// opacity: 0.6 !important;
// }

// ion-button.activated {
// opacity: 0.4 !important;
// }

.action-sheet-button-inner {
  justify-content: center !important;
}

.sc-ion-action-sheet-ios .action-sheet-button-inner {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Roboto', sans-serif;
  color: #3880ff;
}

.sc-ion-action-sheet-ios.action-sheet-destructive .action-sheet-button-inner {
  color: var(--ion-color-danger);
}

ion-header ion-buttons ion-icon,
ion-header ion-buttons ion-back-button,
ion-header ion-buttons ion-button[ionBackButton] {
  --icon-font-size: 24px !important;
  --min-width: 24px !important;
  --min-height: 24px !important;
  font-size: 24px !important;
}

ion-item-option {
  --color: #999;
}

.submitButton {
  font-family: 'Brandon Grotesque';
  font-size: 2.3rem;
  font-weight: 800;
  width: 70%;
  height: 48px;
  border-radius: 5px;
  --border-radius: 5px;
  text-transform: capitalize;
  --box-shadow: none;
  max-width: 300px;
}

.ph-btn {
  font-family: 'Brandon Grotesque';
  font-size: 2.3rem;
  font-weight: 800;
  --border-radius: 4px;

  &.ion-color-white {
    --border-style: solid;
    --border-width: 1px;
    --border-color: #e0e0e0;
  }
}

.ph-btn-white {
  --color: #3e424b;
  --background: #fff;
  --background-hover: #fafafa;
  --background-activated: #fafafa;
  --background-focused: #fafafa;

  --border-radius: 5px;
  --border-color: #e0e0e0;
  --border-style: solid;
  --border-width: 1px;
}

ion-buttons[slot='start'] {
  ion-button[ionBackButton] {
    min-width: 35px;
    height: 32px;
    margin-inline-start: 0;
    margin-inline-end: 0;
    --padding-start: 0px !important;
    --padding-end: 0px !important;
    position: relative;
  }

  ion-button[ionBackButton]:after,
  ion-back-button:after {
    content: '';
    position: absolute;
    left: -10px;
    right: -10px;
    top: 0;
    bottom: 0;
  }

  ion-button[ionBackButton].ios {
    min-width: 24px;
    margin-left: 1px;
  }

  .md[ionBackButton] {
    min-width: 48px;
    height: 48px;
    margin-inline-start: 0;
    margin-inline-end: 0;
    --padding-start: 10px !important;
    --padding-end: 10px !important;
  }

  ion-back-button {
    position: relative;

    button {
      --padding-start: 5px;
      --padding-end: 5px;
    }

    .sc-ion-back-button-ios,
    .sc-ion-back-button-md {
      --padding-start: 10px;
      --padding-end: 10px;
    }
  }
}

ion-buttons[slot='end'] {
  .ios.back-button-has-icon-only {
    margin-right: 10px;

    button {
      width: 32px !important;
    }
  }
}

ion-buttons ion-icon {
  font-size: 24px;
}

// Resolve clipping issue on done/undo icons
app-item-toolbar ion-buttons ion-icon {
  width: 100%;
  height: 100%;
}

ion-button {
  --box-shadow: none !important;
}

// - Text
ion-alert .alert-message {
  font-size: 2rem !important;
}

.purpleText {
  font-family: 'Open Sans';
  font-size: 2rem;
  color: #af51dd;
}

.exampleText {
  margin-top: 0;
  font-weight: 800;
  font-size: 2rem;
  color: #999;
}

.explainerText {
  font-weight: normal;
  font-size: 1.6rem;
  color: #282c37;
}

.secondaryTextLink {
  color: #3e424b;
  --color: #3e424b;
  --color-activated: #3e424b;
  text-transform: capitalize;
  text-decoration: underline;
  font-size: 1.3rem;
}

.errorText {
  font-family: 'Open Sans';
  font-size: 1.4rem;
  color: #e93423;
}

// Toast styling
ion-toast {
  --background: var(--ion-color-secondary);
  --color: #fff;
}

ion-toast.noInternetToast {
  --background: #e93423 !important;
  --color: #fff;
}

ion-toast.chat-notification-toast {
  --background: var(--ion-color-tertiary) !important;
  --color: #fff;

  &::part(button) {
    color: #fff;
  }
}

ion-toast.chat-load-fail-toast {
  --background: var(--ion-color-danger) !important;
  --color: #fff;

  &::part(button) {
    color: #fff;
  }
}

// Android tweaks for header bars for ionic 5
.header-md {
  ion-toolbar {
    --padding-top: 4px;
    --padding-bottom: 4px;

    ion-button[ionBackButton],
    ion-back-button {
      min-width: 48px;
      height: 48px;
      margin-inline-start: 0;
      margin-inline-end: 0;
      --padding-start: 10px !important;
      --padding-end: 10px !important;
    }
  }

  ion-menu-toggle>ion-button {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .toolbar-searchbar {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  ion-searchbar {
    padding-top: 12px;
    padding-bottom: 12px;
    height: 60px;
  }
}

.app-header {
  ion-menu-toggle>ion-button {
    --padding-top: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --border-radius: 50%;
  }
}

// Links in messages
.linkified {
  color: #3e424b;
}

.chat-linkified {
  color: inherit;
}

// Loading popup
.loading-content.sc-ion-loading-md,
.loading-content.sc-ion-loading-ios {
  font-weight: normal;
  font-size: 1.8rem;
}

app-dynamic-content {
  &.chat-message {
    display: flex;
    flex-direction: column;

    p {
      // word-wrap fallback until drop ios15 support
      word-wrap: anywhere;
      overflow-wrap: anywhere;

      margin: 0;

      &:empty {
        height: 1em;
      }
    }
  }

  ngx-dynamic-hooks {}
}