// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import 'swiper/scss';
@import 'swiper/scss/autoplay';
@import 'swiper/scss/scrollbar';

.swiper-horizontal>.swiper-scrollbar {
  bottom: unset;
  border-radius: 0;
  width: 100%;
  left: 0;
  background-color: #e6e6e6;

  .swiper-scrollbar-drag {
    background: var(--ion-color-secondary);
    border-radius: 0;
  }
}

.swiper-slide {
  overflow: scroll;
}

.ios .reaction-sheet .action-sheet-group {
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}

.reaction-sheet .action-sheet-group {

  .action-sheet-title {
    text-align: center;
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    color: #282C37;
  }

  .action-sheet-button-inner {
    justify-content: inherit !important;
    font-family: 'Open Sans';
    font-size: 1.6rem;
    color: #3E424B;
  }

  .action-sheet-icon {
    margin: 16px 16px 16px 0;
    padding-bottom: 0;
    font-size: 40px;
  }

  .current .action-sheet-icon {
    border-bottom: solid 2px;

  }
}

/* Allows clamping of autogrow text area e.g. chat send message */
ion-textarea {
  .textarea-wrapper {
    max-height: var(--max-height, none)
  }

  /* ionic adds overflow:hidden when autogrow specified preventing scrolling */
  &.clamped-autogrow textarea {
    overflow-y: auto !important;
  }

  &.ios.clamped-autogrow {
    label.textarea-wrapper {
      border-style: solid;
      border-width: 1px;
      border-color: #b3b3b3;
    }
  }
}