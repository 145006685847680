// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #e58313;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #56d3c1;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #88e0d3;
  --ion-color-secondary-tint: #88e0d3;

  /** tertiary **/
  --ion-color-tertiary: #c28cf5;
  --ion-color-tertiary-rgb: 194, 140, 245;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #CDA1F7;
  --ion-color-tertiary-tint: #CDA1F7;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #e93423;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** black **/
  --ion-color-black: #000000;

  .ion-color-black {
    --ion-color-base: var(--ion-color-black);
  }

  /** white **/
  --ion-color-white: #fff;
  --ion-color-white-rgb: 255,
  255,
  255;
  --ion-color-white-contrast: #3e424b;
  --ion-color-white-contrast-rgb: 62,
  66,
  75;
  --ion-color-white-shade: #fafafa;
  --ion-color-white-tint: #fafafa;

  /** dark **/
  --ion-color-dark: #;
  --ion-color-dark-rgb: 34,
  34,
  34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,
  255,
  255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,
  154,
  162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,
  255,
  255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,
  244,
  244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,
  0,
  0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** font settings **/
  --ion-font-family: 'Brandon Grotesque';
  font-size: 62.5%;
  font-weight: normal;
  text-size-adjust: 100%;

  --ion-toolbar-background: #fff;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

// and used throughout the application.
@font-face {
  font-family: 'Brandon Grotesque';
  font-weight: normal;
  src: url('/assets/fonts/BrandonGrotesque-Regular.otf');
}

@font-face {
  font-family: 'Brandon Grotesque';
  font-weight: 800;
  src: url('/assets/fonts/BrandonGrotesque-Medium.otf');
}

@font-face {
  font-family: 'Brandon Grotesque';
  font-weight: bold;
  src: url('/assets/fonts/BrandonGrotesque-Bold.otf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  src: url('/assets/fonts/OpenSans-Semibold.ttf');
}